

import service from '../request/index'

export function configInfo(data) {
  return service({
    url: '/conf/index',
    method: "post",
    data
  })
}

export function footerList(data) {
  return service({
    url: '/footervalue/index',
    method: "post",
    data
  })
}
export function footerInfo(data) {
  return service({
    url: '/footermenu/info',
    method: "post",
    data
  })
}
//首页页脚
export function footervalueInfo(data) {
  return service({
    url: '/footermenu/footermenu',
    method: "post",
    data
  })
}
//个人资料
export function memberIndex(data) {
  return service({
    url: '/member/personindex',
    method: "post",
    data
  })
}
//个人资料更新
export function personedit(data) {
  return service({
    url: '/member/personedit',
    method: "post",
    data
  })
}
//银行
export function moneyBank(data) {
  return service({
    url: '/money/bank',
    method: "post",
    data
  })
}
//我的账户--我的推广-佣金流水
export function moneyIndex(data) {
  return service({
    url: '/money/index',
    method: "post",
    data
  })
}
//我的账户--我的推广-邀请人员
export function memberIn(data) {
  return service({
    url: '/member/index',
    method: "post",
    data
  })
}
//我的账户--推荐好友
export function recommend(data) {
  return service({
    url: '/member/recommend',
    method: "post",
    data
  })
}
//我的账户---我的推广-佣金
export function commissions(data) {
  return service({
    url: '/member/commission',
    method: "post",
    data
  })
}
//推荐好友 二维码
export function memberCode(data) {
  return service({
    url: '/member/code',
    method: "post",
    data
  })
}

//修改密码
export function modifyPassword(data) {
  return service({
    url: '/member/password',
    method: "post",
    data
  })
}
//邀请码
export function QrCode(data) {
  return service({
    url: '/member/procode',
    method: "post",
    data
  })
}

//获取银行
export function moneyList(data) {
  return service({
    url: '/money/bank',
    method: "post",
    data
  })
}
//提现
export function cashout(data) {
  return service({
    url: '/cashout/edit',
    method: "post",
    data
  })
}

//获取验证码
export function newsconfig(data) {
  return service({
    url: '/address/newsconfig',
    method: "post",
    data
  })
}

import i18n from './lang'
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import routerYD from './router/indexYD'
import Print from './request/print'

import axios from 'axios'
import VueAxios from 'vue-axios'
import Vant from 'vant';
import 'vant/lib/index.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import AMapLoader from "@amap/amap-jsapi-loader" //引入高德地图插件
import store from './store'
import moment from "moment";
import './assets/icons/index.js';
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.use(i18n)
Vue.filter('dateFormat', (dateStr, pattern = 'YYYY-MM-DD HH:mm:ss') => {
	return moment(dateStr).format(pattern)
})


Vue.use(Print)
Vue.use(ElementUI)
Vue.use(Vant);

Vue.use(VueAxios, axios)
Vue.config.productionTip = false

AMapLoader.load({
	'key': '1876401f6725e1c0d770eb344d0d03cb',
	'version': '2.0', // 指定要加载的 JSAPI 的版本，缺少时默认为 1.4.15
	'plugins': ['AMap.Scale', 'AMap.GeoJSON'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等，更多插件请看官方文档
}).then((AMap) => {
	Vue.use(AMap)
})

const ISMOBILE = function () {
	const flag = navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	);
	return flag;
}
new Vue({
	render: h => h(App),
	router: ISMOBILE() ? routerYD : router,
	store,
	i18n,
}).$mount('#app')

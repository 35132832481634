module.exports = {
	login: {
		'title': 'English',
		'login': 'Login',
		'logined': 'Welcome',
		'Personal': 'Personal Center',
		'password': 'Change password',
		'exit': 'Log out of login',
		'welcome': 'Welcome to login',
		'shortcut': 'Quick login',
		'passwork': 'Password login',
		'ObtainVerificationCode': 'get Code',
		countdown: 'countdown',
		'cellPhoneNumber': 'EntercellPhoneNumber',
		'code': 'Entercode',
		'userName': 'EnteruserName',
		'passwork1': 'Enterpasswork',
		login1: 'login',
		read: 'read login front ',
		privacy: '《Privacy Agreement》',
	},
	tabs: {
		title1: 'home',
		title2: 'ACCOUNT',
		title3: 'MyOrder',
		title4: 'Notify',
	},
	head:{
		title:'Notify',
		title1:'All（',
		title2:'order（',
		title3:'Integral（',
		title4:'Message Details',
		title5:'confirm',
	},
	title: 'Professional platform for overseas Chinese purchasing goods on behalf of others',
	homeLabel: {
		name1: 'User registration and order placement',
		title1: 'Search for products or submit website addresses, pay product prices and domestic shipping costs',

		name2: 'Procurement and inspection',
		title2: 'Order goods, verify and inspect them, weigh and store them in storage',

		name3: 'User submits shipment',
		title3: 'Submit shipment, pay international shipping and service fees',

		name4: 'pack',
		title4: 'Product packaging, weighing, and shipping',

		name5: 'User submits shipment',
		title5: 'Happy to receive the goods, please contact after-sales if there are any problems',
	},
	footer: {
		hotline: 'Customer service hotlin',
		ServiceInbox: 'Customer service mailbox',
		suggestions: 'Complaint suggestion email',
		FollowWX: 'Follow WeChat',
		mobileHAITAO: 'Mobile HAITAO'
	},
	account0: {
		title: 'my account number',
		myAccount: 'my account',
		myaddress: 'my address',
		myintegral: 'my integral',
		RecommendedFriends: 'Recommended friends',
		mypromotion: 'my promotion',
		personalData: 'personal data',
		ContactUs: 'Contact Us',
		ChangePassword: 'Change password',
		hello: 'Hello',
		StartBecomingVip: 'Start Becoming Vip',
		LatestNews: 'Latest News',
		CreateOrder: 'Create Order',
		OrderNotice: 'Dont miss the latest order notification, check it out quickly'
	},
	account1: {
		title: 'Manage shipping addresses',
		empty: 'Data is empty',
		default: 'Set as default delivery address',
		addAddress: 'New Address',
		residence: 'Residential/non business address',
	},
	account2: {
		title: 'My points',
		available: 'Available points',
		integralAll: 'Total Score',
		ConversionProcess: 'Points can be obtained through the conversion of platform order payment amounts, and currently the platform points are converted！',
		IntegralFlow: 'Integral flow',
		IntegralTransfer: 'Integral transfer',
		empty: 'Data is empty',
		integral: 'integral',
		OrderNo: 'Order No.',
		notes: 'notes',
		creationDate: 'creation date',
		record: 'Only show the transaction records of points within the last 90 days.',
		TransferPhone: 'Enter transfer phone number',
		InputTransferPoints: 'Input transfer points',
		rule: 'For example, if there are 100 points, you can enter 50 and only transfer 50',
		approveSum: 'Integral transferable',
		confirm: 'confirm',
	},
	account3: {
		title: 'Recommended friends',
		invite: 'Invite friends',
		brief: 'Share the invitation QR code, invite friends to register, and the order amount for inviting friends will be divided into commission income based on the configuration ratio',
		ScanCode: 'Please scan the code to invite friends',
		DownloadQRCode: 'Download QR code',
	},
	account4: {
		title: 'My promotion',
		TotalCommission: 'Total commission:',
		WithdrawableCommission: 'Withdrawable commission:',
		Withdrawal: 'Withdrawal',
		type: 'type',
		time: 'time',
		CommissionBalance: 'Commission balance',
		InvitationTime: 'Invitation time',
		WithdrawalToCankCard: 'Withdrawal to bank card',
		WithdrawalAmount: 'Withdrawal amount',
		approveWithdrawalAmount: 'Withdrawable amount',
		first: 'first',
		confirm: 'confirm',
		empty: 'Data is empty',
		statement1: 'Commission statement',
		personnel1: 'Invite personnel',
		bank1: 'Please select a bank',
		Enterwithdrawalamount: 'Enter withdrawal amount',
	},
	account5: {
		title: 'personal data',
		vip: 'Member Code',
		realname: 'name',
		surname: 'surname',
		birthday: 'date of birth',
		mobile: 'phone number',
		// title:'通讯语言',
		brief: 'I hope to receive the latest activities of overseas Taobao agents and overseas store discounts and other push notifications through SMS (we will not transfer your personal information to other companies or third parties)',
		cancellation: 'cancellation',
		renew: 'renew',
		PleaseFillIn: 'Please Fill in',
	},
	account6: {
		ContactUs: 'Contact Us',
		company: 'Hong Kong Haitao Special Agent Co., Ltd',
		WX: 'Customer service WeChat',
		phone: 'customer service telephone numbers',
		follow: 'Follow overseas Taobao agents',
	},
	account7: {
		passwork: 'Change password',
		number: 'cell-phone number',
		code: 'code',
		Password1: 'Password',
		Password2: 'Confirm new password',
		cancellation: 'cancellation',
		save: 'save',
		Please: 'Please Fill in',
		code1: 'get code',
		get: 'Get in seconds',
	},
	order: {
		title: "My Order",
		name1: 'Pending order confirmation',
		name2: 'Waiting for payment order',
		name3: 'Procurement processing',
		name4: 'Purchased orders',
		name5: 'Completed order',
		name6: 'Cancelled order',
		title1: "My receipt",
		name7: 'Receipt Management',
	},
	order0:{
		title:'Pending order confirmation',
		empty:'Data is empty',
		info:'Shipment information',
		details:'Detail',
		number:'Order number',
		time:'Submission time',
		country:'Purchasing country',
		name:'Trade name',
		sum:'Product quantity',
		price:'item pricing',
		prompt:'The system will automatically delete waybills that have exceeded 90 days.',
		title1:'Details of orders to be confirmed',
		establishTime:'Establishment time',
		title2:'Purchase order established',
		orderSum:'Order Quantity',
		cancellationOrder:'cancellation of order',
		Reason:'Reason for cancellation',
		determine:'Confirm',
		input:'Please enter the reason for cancellation'
	},
	order1:{
		title:'Waiting for payment order',
		empty:'Data is empty',
		info:'Shipment information',
		details:'Detail',
		number:'Order number',
		time:'Submission time',
		quotationtime:'Quotation time',
		Amount:'Amount',
		prompt:'The system will automatically delete waybills that have exceeded 90 days.',
		title1:'Waiting for payment details',
		establishTime:'Establishment time',
		country:'Purchasing country',
		title2:'Purchase order established',
		name:'Trade name',
		orderSum:'Order Quantity',
		price:'item pricing',
		title3:'Quoted Information',
		behalf:'Agency purchase fee',
		freight:'freight',
		estimate:'Estimated total fee',
		rate:'exchange rate',
		offset:'Points offset',
		cancellationOrder:'cancellation of order',
		pay:'payment',
		content1:'The actual payable amount will be calculated based on the exchange rate locked when the system confirms the order. Once the exchange rate is locked, it will not change.',
		Reason:'Reason for cancellation',
		determine:'Confirm',
		input:'Please enter the reason for cancellation',
		paymethod:'Choose payment method',
		ZFB:'Alipay',
		WX:'WeChat',
		WX1:'WeChat QR code scanning',
		WX2:'Payment required',
		first:'first',
		cancellationPay:'Cancel payment',
		successPay:'Payment successful',
	},
	order2:{
		title:'Procurement processing',
		empty:'Data is empty',
		info:'Shipment information',
		details:'Detail',
		number:'Order number',
		time:'Submission time',
		quotationtime:'Quotation time',
		Amount:'Amount',
		prompt:'The system will automatically delete waybills that have exceeded 90 days.',

		title1:'Waiting for shipment order details',
		establishTime:'Establishment time',
		country:'Purchasing country',

		title2:'Purchase order established',
		name:'Trade name',
		orderSum:'Order Quantity',
		price:'item pricing',
		
		title3:'Quoted Information',
		Amountpay:'Total amount',
		behalf:'Agency purchase fee',
		freight:'freight',

		title4:'Paid Information',
		payTime:'time of payment',
		payType:'Payment Type',
		payAmount:'Payment amount',
		PointDeduction:'Point deduction',
		actualPay:'actual payment',
	},
	order8:{
		title:'Purchased orders',
		empty:'Data is empty',
		info:'Shipment information',
		details:'Detail',
		number:'Order number',
		time:'Submission time',
		quotationtime:'Quotation time',
		Amount:'Amount',
		prompt:'The system will automatically delete waybills that have exceeded 90 days.',

		title1:'Waiting for shipment order details',
		establishTime:'Establishment time',
		country:'Purchasing country',

		title2:'Purchase order established',
		name:'Trade name',
		orderSum:'Order Quantity',
		price:'item pricing',
		
		title3:'Quoted Information',
		Amountpay:'Total amount',
		behalf:'Agency purchase fee',
		freight:'freight',

		title4:'Paid Information',
		payTime:'time of payment',
		payType:'Payment Type',
		payAmount:'Payment amount',
		PointDeduction:'Point deduction',
		actualPay:'actual payment',

		title5:'Logistics information'
	},
	order3:{
		title:'Completed order',
		empty:'Data is empty',
		info:'Shipment information',
		details:'Detail',
		number:'Order number',
		time:'Submission time',
		quotationtime:'Quotation time',
		sendTime:'delivery time',
		completeTime:'TIME',
		OrderPayAmount:'Order payment amount',
		prompt:'The system will automatically delete waybills that have exceeded 90 days.',

		title1:'Waiting for shipment order details',
		establishTime:'Establishment time',
		country:'Purchasing country',

		title2:'Purchase order established',
		name:'Trade name',
		orderSum:'Order Quantity',
		price:'item pricing',
		
		title3:'Quoted Information',
		Amount:'Amount',
		Amountpay:'Total amount',
		behalf:'Agency purchase fee',
		freight:'freight',
	
		title4:'Paid Information',
		payTime:'time of payment',
		payType:'Payment Type',
		payAmount:'Payment amount',
		PointDeduction:'Point deduction',
		actualPay:'actual payment',

		title5:'Logistics information',
		invoice:'View relevant invoices',

		title6:'Completed Information',
		comTime:'TIME',

	},
	order4:{
		title:'Cancelled order',
		empty:'Data is empty',
		info:'Shipment information',
		details:'Detail',
		number:'Order number',
		time:'Submission time',
		CancelTime:'Cancel time',
		prompt:'The system will automatically delete waybills that have exceeded 90 days.',

		title1:'Cancelled Order Details',
		establishTime:'Establishment time',
		country:'Purchasing country',
		
		title2:'Purchase order established',
		name:'Trade name',
		orderSum:'Order Quantity',
		price:'item pricing',
		
		title3:'Quoted Information',
		Amount:'Amount',
		
		title4:'Canceled',
		Remark:'Remark',

	},
	order5:{
		title:'Receipt Management',
		empty:'Data is empty',
		details:'Detail',
		number:'Order number',
		DeclarationTime:'Declaration time',
		time:'Quotation time',
		deliveryTime:'delivery time',
		orderPay:'Order payment amount',

		title1:'order ',
		title2:'Final details of',
		title3:'Print this page for record keeping',

		
		title4:'Place an order:',
		title5:'Purchase Service Order Number:',
		title6:'Total order amount:',

		send :'send out goods',
		info:'Shipment information',
		establishTime:'Establishment time',
		country:'Purchasing country',

		title7:'Purchase order established',
		name:'Purchase order established',
		orderSum:'Order Quantity',
		price:'item pricing',

		
		title8:'subtotal',
		type:'Payment Type',
		PayTime:'time of payment',
		freight:'freight',
		purchase :'Agency purchase fee',
		preferential:'preferential',
		total:'total',

		title9:'Address',
		name1:'Completed Information',
		comTime:'TIME',
		Status:'To view the order status, please return to',
		Summary :'Order Summary.',
		ONadded :'Please note that this is not a value-added tax invoice',

	},
	order6: {
		title: 'Establish a purchasing order',
		not: 'You have not added any new products yet',
		addproducts: 'New products',
		number: 'Items have been added',
		sum: '(Maximum of 10)',
		add: 'add',
		color: 'Color:',
		size: 'Size:',
		length: 'Length:',
		commodityValue: 'commodity value',
		service: 'service charge',
		Estimated: 'Estimated fees',
		SelectAddress: 'Select shipping address',
		addAddress: 'Add shipping address',
		read: 'I have read and agree',
		NotContaining: 'This order does not contain any',
		perhaps: 'perhaps',
		Transportation: '《Transportation Restrictions and Prohibitions》',
		China: 'China prohibits restricted products',
		Export: 'Export restricted products from the local country',
		stopRead: 'Read and understand',
		ImportInstructions: '《Explanation of Customs Clearance Procedures and Import Duties for Overseas Articles Entering China》',
		cancellation: 'cancellation',
		continue: 'continue',
		transportMethod: 'transportMethod',
		confirm: 'confirm',
		SelectCountry: 'SelectCountry',
		newOrder: 'new Order',
		note: 'note',
		ProductName: 'ProductName',
		ProductAddress: 'ProductAddress',
		behalfSum: 'Purchase quantity on behalf',
		UnitPrice: 'UnitPrice',
		price: 'commodity price',
		next : 'next step',
		EnterProductName : 'Please enter the product name',
		EnterProductAddress : 'Please enter the page address',
		EnterUnitPrice : 'Please enter the selling price',
		ProductDetails : 'Product Details',
		color1 : 'colour',
		size1 : 'size',
		lenght1 : 'Waist/length',
		DiscountCode : 'Discount code',
		EstimatedWeight : 'Estimated weight',
		EstimatedSize : 'Estimated size',
		CommodityPicture : 'Commodity picture',
		Pictures : 'Pictures',
		OTHERS : 'OTHERS',
		save : 'save',
		Entercolor : 'Please enter a color',
		Entersize : 'Please enter the size',
		Enterlenght : 'Please enter waist circumference/length',
		EnterDiscountCode : 'Please enter the discount code',
		EnterEstimatedWeight : 'Please enter estimated weight',
		EnterEstimatedSize : 'Please enter the estimated size',
		EnterProductDescription : 'Product Supplementary Description',
		example : 'Example: Name: Taoxiaote, Mobile number: 134 * * * 9999, Detailed address: A certain community in Lixia District, Jinan City, Shandong Province',
		identify : 'Paste and recognize',
		paste:'Paste information, automatically split names, phone numbers, and addresses',
		name:'name',
		phone:'phone',
		Provinces:'Provinces',
		Address:'Address',
		zipCode:'zipCode',
		default :'Set as default delivery address',
		PrivacyAgreement  :'PrivacyAgreement',
	},
	order7:{
		title:'Order details',
		PurchaseQuantity:'Purchase quantity:',
		singlePiece:'Unit price:',
		initiate:'Initiate immediately',
		ProductAddress :'Product page address:',
		color:'Color:',
		size:'Size:',
		lenght:'Waist/Length:',
		DiscountCode:'Discount code:',
		weight:'Estimated weight:',
		information:'Other information:',
		goods:'Origin of goods:',
	}
}
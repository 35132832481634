module.exports = {
	login: {
		'title': '简体中文',
		'login': '请登录',
		'logined': '欢迎您',
		'Personal': '个人中心',
		'password': '修改密码',
		'exit': '退出登录',
		'welcome': '欢迎登录',
		'shortcut': '快捷登录',
		'passwork': '密码登录',
		'ObtainVerificationCode': '获取验证码',
		'countdown': '倒计时',
		'cellPhoneNumber': '请输入手机号',
		'code': '请输入验证码',
		'userName': '请输入账号',
		'passworke': '请输入密码',
		login1: '登录',
		read: '登陆前请阅读',
		privacy: '《隐私协议》',
	},
	tabs: {
		title1: '首页',
		title2: '我的账户',
		title3: '我的订单',
		title4: '消息通知',
	},
	head:{
		title:'消息通知',
		title1:'全部（',
		title2:'订单（',
		title3:'积分（',
		title4:'消息详情',
		title5:'确认',
	},
	title: '海外华人代购商品专业平台',
	homeLabel: {
		name1: '用户注册下单',
		title1: '搜索商品或提交网址，支付商品价格和国内运费',

		name2: '采购、验货',
		title2: '订购商品，核对验货，称重入库',

		name3: '用户提交发货',
		title3: '提交发货，支付国际运费和服务费',

		name4: '打包',
		title4: '商品打包称重，发货',

		name5: '用户提交发货',
		title5: '开心收货，如遇问题联系售后',
	},
	footer: {
		hotline: '客服热线电话',
		ServiceInbox: '客户服务信箱',
		suggestions: '投诉建议邮箱',
		FollowWX: '海淘特工官方公众号',
		mobileHAITAO: '手机访问海淘特工'
	},
	account0: {
		title: '我的账号',
		myAccount: '我的账户',
		myaddress: '我的地址',
		myintegral: '我的积分',
		RecommendedFriends: '推荐好友',
		mypromotion: '我的推广',
		personalData: '个人资料',
		ContactUs: '联系我们',
		ChangePassword: '修改密码',
		hello: '您好',
		StartBecomingVip: '开始成为会员',
		LatestNews: '最新消息',
		CreateOrder: '创建订单',
		OrderNotice: '不错过最新的订单通知，快快查看'
	},
	account1: {
		title: '管理收货地址',
		empty: '数据为空',
		default: '设定为预设派送地址',
		addAddress: '新增地址',
		residence: '住宅/非工商地址',
	},
	account2: {
		title: '我的积分',
		available: '可用积分',
		integralAll: '总积分',
		ConversionProcess: '积分可通过平台订单支付金额转换获得，目前平台积分转换！',
		IntegralFlow: '积分流水',
		IntegralTransfer: '积分转移',
		empty: '数据为空',
		integral: '积分',
		OrderNo: '订单号',
		notes: '备注',
		creationDate: '建立日期',
		record: '只显示最近90天内的积分交易记录。',
		TransferPhone: '输入转移手机号',
		InputTransferPoints: '输入转移积分',
		rule: '例如：有100积分，可以输入50，只转移50',
		approveSum: '积分可转移',
		confirm: '确认',
	},
	account3: {
		title: '推荐好友',
		invite: '位邀请好友',
		brief: '分享邀请二维码，邀请好友注册，邀请好友订单金额会根据配置比例进行分佣收益',
		ScanCode: '请扫码邀请好友',
		DownloadQRCode: '下载二维码',
	},
	account4: {
		title: '我的推广',
		TotalCommission: '总佣金：',
		WithdrawableCommission: '可提款佣金：',
		Withdrawal: '去提款',
		type: '类型',
		time: '时间',
		CommissionBalance: '佣金余额',
		InvitationTime: '邀请时间',
		WithdrawalToCankCard: '提现到银行卡',
		WithdrawalAmount: '提现金额',
		approveWithdrawalAmount: '可提现金额',
		first: '元',
		confirm: '确认',
		empty: '数据为空',
		statement1: '佣金流水',
		personnel1: '邀请人员',
		bank1: '请选择银行',
		Enterwithdrawalamount: '输入提现金额',
	},
	account5: {
		title: '个人资料',
		vip: '会员编码',
		realname: '名字',
		surname: '姓氏',
		birthday: '出生日期',
		mobile: '手机号码',
		// title:'通讯语言',
		brief: '我希望以短信的方式收到海淘特工的最新活动及海外门店折扣等推送资讯（我们不会将您的个人资料转交给其他公司或第三方）',
		cancellation: '取消',
		renew: '更新',
		PleaseFillIn: '请填写',
	},
	account6: {
		ContactUs: '联系我们',
		company: '香港海淘特工有限公司',
		WX: '客服微信',
		phone: '客服电话',
		follow: '关注海淘特工',
	},
	account7: {
		passwork: '修改密码',
		number: '手机号',
		code: '验证码',
		Password1: '新密码',
		Password2: '确定新密码',
		cancellation: '取消',
		save: '保存',
		Please: '请填写',
		code1: '获取验证码',
		get: '秒后获取',
	},
	order: {
		title: "我的订单",
		name1: '待确认订单',
		name2: '等待付款订单',
		name3: '采购处理中',
		name4: '已采购订单',
		name5: '已完成订单',
		name6: '已取消订单',
		title1: "我的收据",
		name7: '收据管理',

	},
	order0: {
		title: '待确认订单',
		empty: '数据为空',
		info: '代购信息',
		details: '查看详情',
		number: '订单编号',
		time: '提交时间',
		country: '采购国',
		name: '商品名称',
		sum: '商品数量',
		price: '商品单价',
		prompt: '系统会自动删除超过90天的运货单。',
		title1: '待确认订单详情',
		establishTime: '建立时间',
		title2: '已建立采购订单',
		orderSum: '订单数量',
		cancellationOrder: '取消订单',
		Reason: '取消事由',
		determine: '确 定',
		input: '请输入取消事由'
	},
	order1: {
		title: '等待付款订单',
		empty: '数据为空',
		info: '代购信息',
		details: '查看详情',
		number: '订单编号',
		time: '提交时间',
		quotationtime: '报价时间',
		Amount: '订单金额',
		prompt: '系统会自动删除超过90天的运货单。',

		title1: '等待付款详情',
		establishTime: '建立时间',
		country: '采购国',
		title2: '已建立采购订单',
		name: '商品名称',
		orderSum: '订单数量',
		price: '商品单价',

		title3: '已报价信息',
		behalf: '代购费',
		freight: '运费',
		estimate: '预估总收费',
		rate: '汇率',
		offset: '积分抵',
		cancellationOrder: '取消订单',
		pay: '支付',
		content1: '实际应付金额将按照系统确认订单时锁定的汇率计算。汇率一经锁定将不会更改。',
		Reason: '取消事由',
		determine: '确 定',
		input: '请输入取消事由',

		paymethod: '选择支付方式',
		ZFB: '支付宝',
		WX: '微信',
		WX1: '微信扫码',
		WX2: '需支付',
		first: '元',
		cancellationPay: '取消 支付',
		successPay: '支付 成功',
	},
	order2: {
		title: '采购处理中',
		empty: '数据为空',
		info: '代购信息',
		details: '查看详情',
		number: '订单编号',
		time: '提交时间',
		quotationtime: '报价时间',
		Amount: '订单金额',
		prompt: '系统会自动删除超过90天的运货单。',

		title1: '等待发货订单详情',
		establishTime: '建立时间',
		country: '采购国',

		title2: '已建立采购订单',
		name: '商品名称',
		orderSum: '订单数量',
		price: '商品单价',

		title3: '已报价信息',
		Amountpay: '总金额',
		behalf: '代购费',
		freight: '运费',

		title4: '已支付信息',
		payTime: '支付时间',
		payType: '支付类型',
		payAmount: '支付金额',
		PointDeduction: '积分抵扣',
		actualPay: '实际支付',
	},
	order8: {
		title5: '物流信息',
		title: '已采购订单',
		empty: '数据为空',
		info: '代购信息',
		details: '查看详情',
		number: '订单编号',
		time: '提交时间',
		quotationtime: '报价时间',
		Amount: '订单金额',
		prompt: '系统会自动删除超过90天的运货单。',

		title1: '等待发货订单详情',
		establishTime: '建立时间',
		country: '采购国',

		title2: '已建立采购订单',
		name: '商品名称',
		orderSum: '订单数量',
		price: '商品单价',

		title3: '已报价信息',
		Amountpay: '总金额',
		behalf: '代购费',
		freight: '运费',

		title4: '已支付信息',
		payTime: '支付时间',
		payType: '支付类型',
		payAmount: '支付金额',
		PointDeduction: '积分抵扣',
		actualPay: '实际支付',
	},
	order3: {
		title: '已完成订单',
		empty: '数据为空',
		info: '代购信息',
		details: '查看详情',
		number: '订单编号',
		time: '提交时间',
		quotationtime: '报价时间',
		sendTime: '发货时间',
		completeTime: '发货时间',
		OrderPayAmount: '订单支付金额',
		prompt: '系统会自动删除超过90天的运货单。',

		title1: '已完成订单详情',
		establishTime: '建立时间',
		country: '采购国',

		title2: '已建立采购订单',
		name: '商品名称',
		orderSum: '订单数量',
		price: '商品单价',

		title3: '已报价信息',
		Amount: '订单金额',
		Amountpay: '总金额',
		behalf: '代购费',
		freight: '运费',

		title4: '已支付信息',
		payTime: '支付时间',
		payType: '支付类型',
		payAmount: '支付金额',
		PointDeduction: '积分抵扣',
		actualPay: '实际支付',

		title5: '物流信息',
		invoice: '查看清关申请发票',

		title6: '已完结信息',
		comTime: '完成时间',

	},
	order4: {
		title: '已取消订单',
		empty: '数据为空',
		info: '代购信息',
		details: '查看详情',
		number: '订单编号',
		time: '提交时间',
		CancelTime: '取消时间',
		prompt: '系统会自动删除超过90天的运货单。',

		title1: '已取消订单详情',
		establishTime: '建立时间',
		country: '采购国',

		title2: '已建立采购订单',
		name: '商品名称',
		orderSum: '订单数量',
		price: '商品单价',

		title3: '已报价信息',
		Amount: '订单金额',

		title4: '已取消',
		Remark: '备注说明',
	},
	order5: {
		title: '收据管理',
		empty: '数据为空',
		details: '查看详情',
		number: '订单编号',
		DeclarationTime: '申报时间',
		time: '报价时间',
		deliveryTime: '发货时间',
		orderPay: '订单支付金额',

		title1: '订单',
		title2: '的最终详细信息',
		title3: '打印此页以供记录',


		title4: '下单：',
		title5: '代购服务订单号：',
		title6: '订单总额：',

		send: '发货',
		info: '代购信息',
		establishTime: '建立时间',
		country: '采购国',

		title7: '已建立采购订单',
		name: '商品名称',
		orderSum: '订单数量',
		price: '商品单价',


		title8: '小计',
		type: '支付类型',
		PayTime: '支付时间',
		freight: '运费',
		purchase: '代购费',
		preferential: '优惠',
		total: '总额',

		title9: '收货地址',
		name1: '已完结信息',
		comTime: '完成时间',
		Status: '要查看订单状态，请返回',
		Summary: '订单摘要。',
		ONadded: '请注意，这不是增值税税票',
	},
	order6: {
		title: '建立代购订单',
		not: '您还未新增商品',
		addproducts: '新增商品',
		number: '个商品已加入',
		sum: '（上限10个）',
		add: '新增',
		color: '颜色：',
		size: '尺寸：',
		length: '长度：',
		commodityValue: '商品价格',
		service: '代购服务费',
		Estimated: '预估收费',
		SelectAddress: '选择收货地址',
		addAddress: '新增收货地址',
		read: '我已阅读并同意',
		NotContaining: '该订单内未含有任何',
		perhaps: '或',
		Transportation: '《运输限制和禁止条款》',
		China: '中国禁止限制产品',
		Export: '当地国出口限制产品',
		stopRead: '已阅读并知晓',
		ImportInstructions: '《对于海外物品进入中国物品清关手续及进口关税说明》',
		cancellation: '取消',
		continue: '继续',
		transportMethod: '选择运输方式',
		confirm: '确认',
		SelectCountry: '选择国家',
		newOrder: '创建新订单',
		note: '注意事项',
		ProductName: '商品名称',
		ProductAddress: '商品页面地址',
		behalfSum: '代购数量',
		UnitPrice: '单件售价（折扣后）',
		price: '商品价格',
		next : '下一步',
		EnterProductName : '请输入商品名称',
		EnterProductAddress : '请输入页面地址',
		EnterUnitPrice : '请输入售价',
		ProductDetails : '商品详情',
		color1 : '颜色',
		size1 : '尺寸',
		lenght1 : '腰围/长度',
		DiscountCode : '优惠码',
		EstimatedWeight : '预估重量',
		EstimatedSize : '预估尺寸',
		CommodityPicture : '商品图片',
		Pictures : '上传图片',
		OTHERS : '其他资料',
		save : '保 存',
		Entercolor : '请输入颜色',
		Entersize : '请输入尺寸',
		Enterlenght : '请输入腰围/长度',
		EnterDiscountCode : '请输入优惠码',
		EnterEstimatedWeight : '请输入预估重量',
		EnterEstimatedSize : '请输入预估尺寸',
		EnterProductDescription : '商品补充描述',
		example : '示例：姓名：淘小特，手机号：134****9999，详细地址：山东省济南市历下区某小区',
		identify : '粘贴并识别',
		paste:'粘贴信息，自动拆分姓名，电话和地址',
		name:'名字',
		phone:'电话',
		Provinces:'省份区',
		Address:'详细地址',
		zipCode:'邮编',
		default :'设定为预设派送地址',
		PrivacyAgreement  :'隐私协议',
	},
	order7:{
		title:'订单详情',
		PurchaseQuantity:'代购数量：',
		singlePiece:'单件售价：',
		initiate:'立即发起代购',
		ProductAddress :'商品页面地址：',
		color:'颜色：',
		size:'尺寸：',
		lenght:'腰围/长度：',
		DiscountCode:'优惠码：',
		weight:'预估重量：',
		information:'其他资料：',
		goods:'商品产地：',
	}
}
<template>
  <div>
    <div @mouseenter="over" @mouseleave="out" class="container">
      <div class="popup" @transitionend="onTransitionEnd" :class="{ active: isHover }" v-if="isHover">
        <div @click="selectOption(1)">{{ $store.state.infoData.phone }}</div>
        <div @click="selectOption(2)">E-Mail</div>
        <div @click="selectOption(3)" style="border: none;">客服</div>
        <div @click="selectOption(3)" style="border: none; margin-top: 5px;">客服</div>
      </div>
      <div class="img" :style="{ width: boxWidth + 'px' }">
        <!-- @click="openWXKF"  -->
        <img src="../../assets/WX_icon.png" class="wx" alt="" />
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "suspensionBall",
  data() {
    return {
      isLoading: false,
      isHover: false,
      boxWidth: 60,
    };
  },
  methods: {
    openWXKF() {
      window.open(this.$store.state.infoData.service, '_blank');
    },
    out() {
      this.isHover = false;
      this.boxWidth = 60;
    },
    over() {
      this.boxWidth = 120;
      this.isHover = true;
    },
    onClick() { },
    selectOption(i) {
      if (i == 3) {
        window.open(this.$store.state.infoData.service, '_blank');
      }
      if (i == 1) {
        window.open('tel:' + this.$store.state.infoData.phone, '_blank');
      }
      if (i == 2) {
        window.open('mailto:'+this.$store.state.infoData.mail, '_blank');
      }
    },
    onTransitionEnd() {
      // 过渡动画结束后的回调函数
    }
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.wx {
  width: 30px;
  height: 30px;
}

.container {
  position: relative;
  width: 120px;
  /* height: 140px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup {
  width: 100%;
  background-color: #3c3a3a;
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  opacity: 0;
  transition: opacity 2s;
}

.active {
  opacity: 1;
}

.popup div {
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  border-bottom: 1px solid #6d6d6d;
  text-align: center;
}

.img {
  position: absolute;
  bottom: 0;
  line-height: 0;
  background-color: #000;
  padding: 5px 0;
  width: 100%;
  text-align: center;
  border-radius: 15px;
  transition: width .5s;
  cursor: pointer;
}
</style>
<template>
  <div id="app">
    <router-view v-if="show"></router-view>
    <div class="wxKF" v-if="!iscustomerService">
      <customerService ></customerService>
    </div>
  </div>
</template>

<script>
import customerService from "./components/overallSituation/customerService.vue";
import { configInfo, footervalueInfo, memberIndex } from "./api/home";
export default {
  name: "App",
  components: { customerService },
  data() {
    return {
      show: false,
      iscustomerService: false,
    };
  },
  created() {
    this.getRem(1920, 100);
    window.onresize = function () {
      var html = document.getElementsByTagName("html")[0];
      var oWidth =
        document.body.clientWidth || document.documentElement.clientWidth;
      html.style.fontSize = (oWidth / 1920) * 100 + "px";
    };
    // document.title = "海淘特工 - 专注为中国用户提供海外购物服务";
    this.iscustomerService = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    this.getConfigInfo();
    localStorage.setItem("language", "zh_CN");
    this.$i18n.locale = localStorage.getItem("language");
    // if (!localStorage.getItem("language")) {
    //   localStorage.setItem("language", "zh_CN");
    //   this.$i18n.locale = localStorage.getItem("language");
    // } else {
    //   this.$i18n.locale = localStorage.getItem("language");
    // }
  },
  methods: {
    getRem(pwidth, prem) {
      var html = document.getElementsByTagName("html")[0];
      var oWidth =
        document.body.clientWidth || document.documentElement.clientWidth;
      html.style.fontSize = (oWidth / pwidth) * prem + "px";
    },
    //获取配置信息
    getConfigInfo() {
      let promise1 = configInfo();
      let promise2 = footervalueInfo();
      let promise3 = memberIndex();
      Promise.all([promise1, promise2, promise3])
        .then((result) => {
          result.forEach((item, index) => {
            if (index == 0) {
              if (item?.code == 0) {
                this.$store.dispatch("infoData", item.data);
              }
            } else if (index == 1) {
              if (item?.code == 0) {
                this.$store.dispatch("footerList", item.data);
              }
            } else if (index == 2) {
              if (item?.code == 0) {
                this.$store.dispatch("userInfo", item.data);
              }
            }
          });
          this.show = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
/* 隐藏垂直滚动条 */
::-webkit-scrollbar {
  width: 0;
}
 
.wxKF{
  position: fixed;
  right: 7%;
  bottom: 5%;
}
* {
  padding: 0;
  margin: 0;
}

.privacy {
  width: 15rem !important;
  margin-top: 1vh !important;
}

.el-message .el-icon-error {
  font-size: 18px;
  line-height: 0;
}

.el-message .el-icon-success {
  font-size: 18px;
  line-height: 0;
}

.el-message .el-icon-info {
  font-size: 18px;
  line-height: 0;
}

.default {
  width: 100%;
  text-align: center;
  padding-bottom: 0.5rem !important;
  box-sizing: border-box;
}

.default .top_img {
  width: 5.76rem;
  height: 3rem;
}

.default p {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-top: 10px;
}

.card:hover {
  background-color: #ebf3ff;
}

</style>

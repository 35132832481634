// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import { Message } from 'element-ui';
//1. 创建新的axios实例，
const service = axios.create({
  // 公共接口--这里注意后面会讲
  baseURL: process.env.VUE_APP_API_URL,
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 3 * 1000
})
// 2.请求拦截器
service.interceptors.request.use(config => {
  // config.data = JSON.stringify(config.data); //数据转化,看情况要不要
  config.headers = {
    'Content-Type': 'application/json',//配置请求头
    "token": localStorage.getItem('token'),
  }

  return config
}, error => {
  Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
  //接收到响应数据并成功后的一些共有的处理，关闭loading等login
  if (response.data.code == 0) {
    return response.data
  } else if (response.data.code == 401) {
    localStorage.removeItem('token')
  } else {
    if(response.data.msg){
      Message.error(response.data.msg);
      return Promise.resolve(response.data.msg)
    }
    Message.error(response.data.message);
    return Promise.resolve(response.data.msg)
  }

}, error => {
  console.log(error);
  Message.error('发生了未知错误，请联系客服或刷新重试')

  return Promise.resolve('发生了未知错误，请联系客服或刷新重试')
})
//4.导入文件
export default service
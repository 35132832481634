import Vue from 'vue'
import VueRouter from 'vue-router'
// import { Message } from 'element-ui';
Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        //首页
        {
            path: '/',
            component: () => import("@/pages/Home/HomeIndex.vue"),
            name:'首页'
        }
        //关于我们
        , {
            path: "/account",
            component: () => import("@/pages/Account/AccountIndex.vue")
        }
        //登录
        , {
            path: "/login",
            component: () => import("@/pages/Login/LogIn.vue")
        }
        //订单
        , {
            path: "/order",
            component: () => import("@/pages/Order/OrderList.vue")
        },
        {
            path: "/blank",
            component: () => import("@/pages/blank/blankIndex.vue")
        },
        //帮助
        {
            path: "/help",
            component: () => import("@/pages/help/helpIndex.vue"),
            redirect: '/help/novice',
            children: [
                {
                    path: '/help:id?/novice/:id',
                    component: () => import("@/pages/help/novice/noviceList.vue"),
                }, {
                    path: '/help/novice/noviceDetails/:id',
                    component: () => import("@/pages/help/novice/noviceDetails.vue"),
                },]
        },
    ]
})
router.beforeEach((to, from, next) => {
    var token = localStorage.getItem('token')
    if (token) {
        next()
    } else if (to.path == '/' || to.path == '/login') {
        if (to.path == '/login') {
            next()
            return
        }
        // Message.error('当前未登录，请重新登陆')
        next()
    } else if (to.path == '/order' && to.query.index == 7) {
        next()
        return
    }else if(to.path.includes('help')){
        next()
    } 
    else {
        // Message.error('当前未登录，请重新登陆')
        next({ path: '/login' })
    }
})
export default router;
<template>
    <svg class="svg-icon" aria-hidden="true" :style="{width:width+'px',height:height+'px'}">
      <use :xlink:href="iconName" />
    </svg>
  </template>
   
  <script>
  export default {
    name: "SvgIcon",
    props: {
      iconClass: {
        type: String,
        required: true,
      },
      width:{
        type: Number,
        default:20
      },
      height:{
        type: Number,
        default:20
      }
    },
    computed: {
      iconName() {
        return `#icon-${this.iconClass}`;
      },
    },
  };
  </script>
   
  <style scoped>
  .svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em; 
    fill: currentColor; 
    overflow: hidden;
  }
  </style>
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    //首页
    {
      path: '/',
      component: () => import("@/pages-yd/homeYD.vue")
    }
  ]
})
export default router;